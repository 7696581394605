import { Action, ActionFormAttributes, Permission, PermissionType, User, UserRole } from '../models';
import { getUserId, hasPermissionType, isUserRoleEqualsTo } from '.';
import moment from 'moment';

export interface ActionPermissions {
  create: boolean;
  write: boolean;
  changeStatus: boolean;
  delete: boolean;
  edit: boolean;
  reassign: boolean;
}

export function getActionPermissions(action?: Action, permissions?: Permission[]): ActionPermissions {
  return {
    create: canCreateAction(permissions),
    write: canWriteAction(),
    changeStatus: canChangeActionStatus(permissions),
    delete: canDeleteAction(),
    edit: canEditActionDetails(permissions),
    reassign: canReassignAction(permissions),
  };
}

export function canCreateAction(permissions?: Permission[]): boolean {
  return hasPermissionType(PermissionType.ACTIONS_CREATE, permissions);
}

export function canWriteAction(permissions?: Permission[]): boolean {
  const writePermissionTypes = [
    PermissionType.ACTIONS_EDIT,
    PermissionType.ACTIONS_ASSIGN,
    PermissionType.ACTIONS_CHANGE_STATUS,
  ];
  for (const type of writePermissionTypes) {
    return (
      hasPermissionType(type, permissions) ||
      isUserRoleEqualsTo(UserRole.SUPER_ADMIN) ||
      isUserRoleEqualsTo(UserRole.OWNER)
    );
  }
  return false;
}

export function canChangeActionStatus(permissions?: Permission[]): boolean {
  return hasPermissionType(PermissionType.ACTIONS_CHANGE_STATUS, permissions);
}

export function canDeleteAction(): boolean {
  return isUserRoleEqualsTo(UserRole.SUPER_ADMIN) || isUserRoleEqualsTo(UserRole.OWNER);
}

export function canEditActionDetails(permissions?: Permission[]): boolean {
  return hasPermissionType(PermissionType.ACTIONS_EDIT, permissions);
}

export function canReassignAction(permissions?: Permission[]): boolean {
  return hasPermissionType(PermissionType.ACTIONS_ASSIGN, permissions);
}

export function getNewActionHistory(): ActionFormAttributes {
  return {
    dueDate: [],
    importance: [],
    otherInformation: [],
    requiredAction: [],
    responsibleUserId: [],
    responsibleUserName: [],
  };
}

export const isLoggedInUserCreatorOrSuperAdminOrOwner = (action?: Action, user?: User) => {
  return user?.id === action?.createdByUserId || user?.role === UserRole.SUPER_ADMIN || user?.role === UserRole.OWNER;
};

export const isDateBefore = (record: Action) => {
  const currentDate = moment();
  if (record.dueDate) {
    return currentDate.toISOString() > moment(record.dueDate).toISOString();
  }
  return false;
};
