import { isUndefined } from 'lodash';
import { Action, ActionForm, ActionFormKey, LogEntry } from '../models';
import { getNewLogEntry } from '../utils';

class ActionEditService {
  public getFormInterface(action: Action): Readonly<ActionForm> {
    const form: ActionForm = { ...action };
    return form;
  }

  public fieldChanged(formInterface: ActionForm, key: ActionFormKey, value: any): void {
    formInterface[key] = value;
  }

  public isValid(formInterface: ActionForm): boolean {
    return (
      !isUndefined(formInterface.requiredAction) &&
      !isUndefined(formInterface.responsibleUserId) &&
      !isUndefined(formInterface.dueDate) &&
      !isUndefined(formInterface.importance)
    );
  }

  public applyInterfaceAndReturn(formInterface: ActionForm, action: Action): Action {
    Object.keys(formInterface).forEach((k) => {
      const key: ActionFormKey = k as ActionFormKey;
      if (action[key] !== formInterface[key]) {
        const newEntry: LogEntry = getNewLogEntry(action.history[key], formInterface[key] as string);
        action.history[key].push(newEntry);
      }
    });
    return { ...action, ...formInterface };
  }
}

export default new ActionEditService();
