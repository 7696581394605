import { SortOrder } from 'antd/lib/table/interface';
import {
  ActionImportance,
  ActionSourceType,
  ActionStatus,
  CONTACT_WITH,
  ENVIRONMENT,
  EQUIPMENT_MATERIAL,
  FormResultType,
  HIRE_ENVIRONMENT,
  HIRE_EQUIPMENT,
  HIRE_MATERIALS,
  HIRE_PEOPLE,
  INCIDENT_ENVIRONMENT,
  INCIDENT_EQUIPMENT_MATERIAL,
  INCIDENT_PEOPLE,
  INCIDENT_SLIPS_TRIPS_FALLS,
  IncidentType,
  OTHER,
  PEOPLE,
  PROCESS_AND_PROCEDURE,
  SLIPS_TRIPS_FALLS,
  STRUCK_BY,
  UserRole,
  WORKING_SCHEDULE,
} from '../models';
import { COLORS } from './colors';
import { FILE_SIZE_UNITS } from './enums';
import { TERMS_AND_CONDITIONS_EN, TERMS_AND_CONDITIONS_FR } from './termsAndConditions';
import {
  CausesType,
  HireTypes,
  IncidentCausesType,
  IncidentReasonsType,
  Language,
  RadioGroupOption,
  ReasonsType,
  TermsAndConditionsSection,
} from './types';

// App

// If RW_ROLES or WEB_ACCEPTED_ROLES are changed, the roles for the pages in routes.tsx need to be checked as well
export const RW_ROLES = [UserRole.ADMIN, UserRole.OWNER, UserRole.SUPER_ADMIN];

export const WEB_ACCEPTED_ROLES = [
  UserRole.ADMIN,
  UserRole.OWNER,
  UserRole.EMPLOYEE,
  UserRole.SUPER_ADMIN,
  UserRole.LIMITED_EMPLOYEE_DIVISION,
  UserRole.LIMITED_EMPLOYEE_PERSONAL,
];

export const MAIN_REPORT_TYPES = [IncidentType.INCIDENT, IncidentType.NEAR_HIT];

export const TYPE_SWAP_GROUPS: IncidentType[][] = [
  [...MAIN_REPORT_TYPES, IncidentType.HOUSE_KEEPING],
  [IncidentType.AUDIT, IncidentType.SAFETY_CONV, IncidentType.TBOX],
];

export const TEXT_AREA_MAX_LENGTH = 1024;

export const DATE_LOCALE = 'en-GB';

export const DB_DATE_FORMAT = 'YYYY-MM-DD';

export const DATE_FORMAT = 'DD/MM/YYYY';

export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';

export const LANGUAGES: Language[] = [
  { id: 'en', name: 'English', countryCode: 'GB' },
  { id: 'fr', name: 'Français', countryCode: 'FR' },
  { id: 'nl', name: 'Nederlands', countryCode: 'NL' },
  { id: 'de', name: 'Deutsch', countryCode: 'DE' },
  { id: 'it', name: 'Italiano', countryCode: 'IT' },
  { id: 'es', name: 'Español', countryCode: 'ES' },
  { id: 'fi', name: 'Suomalainen', countryCode: 'FI' },
  { id: 'sv', name: 'Svenska', countryCode: 'SE' },
  { id: 'pl', name: 'Polski', countryCode: 'PL' },
  { id: 'no', name: 'Norsk', countryCode: 'NO' },
  { id: 'ee', name: 'Eesti keel', countryCode: 'EE' },
  { id: 'lt', name: 'Lietuvių', countryCode: 'LT' },
  { id: 'cz', name: 'Čeština', countryCode: 'CZ' },
  { id: 'hr', name: 'Hrvatski', countryCode: 'HR' },
  { id: 'dk', name: 'Dansk', countryCode: 'DK' },
  { id: 'gr', name: 'Ελληνικά', countryCode: 'GR' },
  { id: 'is', name: 'Íslenska', countryCode: 'IS' },
  { id: 'pt', name: 'Português', countryCode: 'PT' },
  { id: 'ro', name: 'Română', countryCode: 'RO' },
  { id: 'ru', name: 'Русский', countryCode: 'RU' },
  { id: 'tr', name: 'Türkçe', countryCode: 'TR' },
  { id: 'ua', name: 'Українська', countryCode: 'UA' },
];

export const DEFAULT_LANGUAGE = LANGUAGES[0];

export const TERMS_AND_CONDITIONS: Record<string, TermsAndConditionsSection[]> = {
  en: TERMS_AND_CONDITIONS_EN,
  fr: TERMS_AND_CONDITIONS_FR,
};

// HTTP & URL

export const AUTHORIZATION = 'authorization';
export const COMPANY_FILTER = 'company-filter';
export const EVENT_TABLE_FILTERS = 'event-table-filters';
export const ACTION_TABLE_FILTERS = 'action-table-filters';
export const CHART_PAGE_STATE = 'chart-page-state';
export const ACCEPT_LANGUAGE = 'Accept-Language';
export const TOKEN_KEY = 'token';
export const INDIVIDUAL_DIVISION_SELECT = 'individual-division-select';

export const MAIN_URLS = Object.freeze({
  LOGIN: ['login'],
  RECOVER: ['recover'],
  ACCOUNT_VALIDATION: ['user', 'confirm'],
  DASHBOARD: ['home'],
  INCIDENT_LIST: ['events'],
  INCIDENT: ['events', 'event'],
  INVESTIGATION: ['events', 'investigation'],
  REVIEW: ['events', 'review'],
  ACTION: ['events', 'action'],
  CHART: ['charts', 'library', 'new', 'edit'],
  EXPORT_PDF: ['events', 'export'],
  EVENT_TYPE_SWAP: ['events', 'swap'],
  SETTINGS: ['settings'],
  SETTINGS_USERS: ['settings', 'users'],
  SETTINGS_CONTACTS: ['settings', 'contacts'],
  SETTINGS_PHONE_NUMBERS: ['settings', 'phone-numbers'],
  SETTINGS_INVITATIONS: ['settings', 'my-invitations'],
  COMPANIES: ['companies'],
  NOT_FOUND: ['not-found'],
  SERVER_ERROR: ['error'],
  PRIVACY_POLICY: ['privacy-policy'],
  CONTACT: ['contact'],
  ACTION_LIST: ['actions'],
  TEAMS: ['teams'],
});

// Regex

export const PASSWORD_REGEX = /^(?=.*[a-z])[a-zA-Z\d\W_]{8,}$/g;

export const INVITE_SIGN_UP_EMAIL_REGEX = /^[^\s][a-zA-Z0-9(.|\-|_|^|+)]+$/g;

export const INVITE_SIGN_UP_EMAIL_WITH_DOMAIN_REGEX =
  /^[^\s][a-zA-Z0-9(.|\-|_|^|+)]+@[^\s][a-zA-Z0-9(.|\-|_|^|+)]+\.[A-Za-z]{2,}/g;

export const DELIMITER = '$$$';

// Attachments

export const MAX_FILES_PER_UPLOAD = 5;

export const REGULAR_FILE_LIMIT = { size: 50, unit: FILE_SIZE_UNITS.MB };

export const VIDEO_FILE_LIMIT = { size: 100, unit: FILE_SIZE_UNITS.MB };

export const ALLOWED_FILE_TYPES = {
  Document: [
    'text/csv',
    'text/plain',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.oasis.opendocument.presentation',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.text',
    'application/pdf',
  ],
  Archive: ['application/vnd.rar', 'application/zip', 'application/x-zip-compressed', 'application/x-7z-compressed'],
  Audio: ['audio/mpeg', 'audio/wav', 'audio/webm'],
  Image: ['image/png', 'image/jpeg', 'image/tiff', 'image/webp'],
  Video: ['video/mp4', 'video/mpeg', 'video/webm'],
};

export const ALLOWED_EXTENSTIONS: string[] = [
  '.csv',
  '.txt',
  '.xls',
  '.xlxs',
  '.ppt',
  '.pptx',
  '.doc',
  '.docx',
  '.odp',
  '.ods',
  '.odt',
  '.pdf',
  '.rar',
  '.zip',
  '.7z',
  '.mpeg',
  '.wav',
  '.webm',
  '.png',
  '.jpeg',
  '.tiff',
  '.webp',
  '.mp4',
  '.mpeg',
  '.webm',
];

export const CSV_EXTENSION = '.csv';

// Maps

export const LIBRARIES = ['places', 'visualization', 'drawing', 'geometry'];

export const MIN_ZOOM = 1;

export const MAX_ZOOM = 18;

export const DEFAULT_MAP_ZOOM = 7;

export const DEFAULT_MAP_CENTER: google.maps.LatLngLiteral = {
  lat: 51.509865,
  lng: -0.118092,
};

// Chat bot

export const CHAT_BOT_DATETIME = 'datetime';

export const CHAT_BOT_MAPS = 'maps';

export const CHAT_BOT_ADDRESS = 'address';

export const CHAT_BOT_ORG_SELECTION = 'orgselection';

export const CHAT_BOT_IMAGE_PICKER = 'imagepicker';

export const ORG_SELECTION = 'ORG_SELECTION';

export const CHAT_BOT_THEME = {
  background: COLORS.WHITE,
  fontFamily: 'Montserrat',
  headerBgColor: COLORS.SIDE_BAR,
  headerFontColor: COLORS.WHITE,
  headerFontSize: '14px',
  botBubbleColor: COLORS.SECONDARY_GREEN,
  botFontColor: COLORS.WHITE,
  userBubbleColor: COLORS.PRIMARY_BLUE,
  userFontColor: COLORS.WHITE,
};

// Event Report

export const IGNORED_TYPES = [FormResultType.SUB_REASON, FormResultType.SUB_CAUSE];

export const TRUTHFUL_VALUES = ['yes', 'oui', 'true', '1'];

export const FORM_RESULT_KEYS = {
  EVENT_TYPE: 'EVENT_TYPE',
  REPORT_DATE: 'REPORT_DATE',

  WHEN_HAPPEN: 'WHEN_HAPPEN',
  LOCATION_INFO: 'LOCATION_INFO',
  WHERE_DID_IT_HAPPEN: 'WHERE_DID_IT_HAPPEN',

  ORG_SELECTION: 'ORG_SELECTION',
  INVOLVED_PERSON_TYPE: 'INVOLVED_PERSON_TYPE',
  WHAT_HAPPENED_DETAILS: 'WHAT_HAPPENED_DETAILS',
  HAZARD_DESCRIPTION: 'HAZARD_DESCRIPTION',
  SUBJECT: 'SUBJECT',

  REASON: 'PRELIMINARY_REASON',
  CAUSE: 'WHAT_DO_YOU_BELIEVE_IS_THE_INITIAL_ROOT_CAUSE_THOUGHT',
  SUB_REASON: 'PRELIMINARY_SUB_REASON',
  SUB_CAUSE: 'WHAT_DO_YOU_BELIEVE_IS_THE_INITIAL_ROOT_CAUSE_THOUGHT_SUB_CAUSE',

  REPORTING_PERSON_IS_VICTIM: 'REPORTING_PERSON_IS_VICTIM',
  VICTIM_CONTACT_FIRST_NAME: 'VICTIM_CONTACT_FIRST_NAME',
  VICTIM_CONTACT_LAST_NAME: 'VICTIM_CONTACT_LAST_NAME',
  VICTIM_CONTACT_EMAIL: 'VICTIM_CONTACT_EMAIL',
  VICTIM_CONTACT_PHONE: 'VICTIM_CONTACT_PHONE',

  WAS_THERE_EQUIPMENT_MACHINERY_OR_TOOLS_INVOLVED: 'WAS_THERE_EQUIPMENT_MACHINERY_OR_TOOLS_INVOLVED',
  EQUIPMENT_MACHINERY_OR_TOOLS_SERIAL_NUMBER: 'EQUIPMENT_MACHINERY_OR_TOOLS_SERIAL_NUMBER',

  HIGH_POTENTIAL_SCENARIO: 'HIGH_POTENTIAL_SCENARIO',
  COULD_RESULT_IN_THREAT_OF_LIFE_OR_LIFE_CHANGING_INJURIES: 'COULD_RESULT_IN_THREAT_OF_LIFE_OR_LIFE_CHANGING_INJURIES',
  WAS_THERE_POTENTIAL_HARM_OR_PROPERTY_DAMAGE: 'WAS_THERE_POTENTIAL_HARM_OR_PROPERTY_DAMAGE',
  COULD_RESULT_IN_TIME_OFF_WORK_OR_DOCTOR_TREATMENT: 'COULD_RESULT_IN_TIME_OFF_WORK_OR_DOCTOR_TREATMENT',

  TYPE: 'TYPE',
  DISCUSSION_TRANSCRIPT: 'DISCUSSION_TRANSCRIPT',
  PARTIES_INVOLVED: 'PARTIES_INVOLVED',
  WHAT_HAVE_YOU_DONE: 'WHAT_HAVE_YOU_DONE',
  IS_SITUATION_SAFE: 'IS_SITUATION_SAFE',
  IS_STILL_UNSAFE: 'IS_STILL_UNSAFE',
};

export const IGNORED_KEYS = [
  'REPORTING_PERSON_NAME',
  'REPORTING_PERSON_CONTACT_DETAILS',
  'WHAT_DO_YOU_BELIEVE_IS_THE_INITIAL_ROOT_CAUSE_THOUGHT_SUB_CAUSE',
  'VICTIM_CONTACT_EMAIL',
  'VICTIM_CONTACT_PHONE',
  'SOMEONE_INVOLVED',
];

export const EventEnumLabels: Map<string, string> = new Map([
  ['CONTACT_WITH', 'questionnaire.contactWith'],
  ['STRUCK_BY', 'questionnaire.struckBy'],
  ['SLIPS_TRIPS_FALLS', 'questionnaire.stf'],
  ['OTHER', 'questionnaire.other'],
  ['UTILITIES_CHEMICALS_FIRE', 'questionnaire.ucf'],
  ['MOVING_VEHICLE', 'questionnaire.movingVehicle'],
  ['MOVING_MACHINERY', 'questionnaire.movingMachinery'],
  ['FIXED_OR_STATIONARY_OBJECT', 'questionnaire.fixedOrStationaryObject'],
  ['MOVING_OBJECT', 'questionnaire.movingObject'],
  ['FLYING_OBJECT', 'questionnaire.flyingObject'],
  ['FALLING_OBJECT', 'questionnaire.fallingObject'],
  ['SLIP', 'questionnaire.slip'],
  ['TRIP', 'questionnaire.trip'],
  ['FALLS_FROM_HEIGHT', 'questionnaire.fallFromHeight'],
  ['FALLS', 'questionnaire.fall'],
  ['MANUAL_HANDLING_OR_ERGONOMICS', 'questionnaire.manualHandling'],
  ['TRAPPED_OR_CRUSHED', 'questionnaire.trappedCrushed'],
  ['MISS_FIRE', 'questionnaire.missFire'],
  ['PEOPLE', 'questionnaire.people'],
  ['ENVIRONMENT', 'questionnaire.env'],
  ['EQUIPMENT_MATERIAL', 'questionnaire.equipMaterial'],
  ['PROCESS_AND_PROCEDURE', 'questionnaire.procProd'],
  ['BEHAVIOUR', 'questionnaire.beh'],
  ['CAPABILITIES_COMPETENCE', 'questionnaire.capCompetence'],
  ['COMMUNICATION', 'questionnaire.comm'],
  ['SUPERVISION', 'questionnaire.supervision'],
  ['HEALTH_FATIGUE', 'questionnaire.healthFatigue'],
  ['DISTRACTION', 'questionnaire.distraction'],
  ['SITE_ENVIRONMENTAL_CONDITIONS', 'questionnaire.siteConditions'],
  ['LAYOUT_SPACE', 'questionnaire.layoutSpaceLighting'],
  ['WORKING_ENVIRONMENT', 'questionnaire.workingEnv'],
  ['HOUSEKEEPING', 'questionnaire.housekeeping'],
  ['VANDALISM_SABOTAGE', 'questionnaire.vandalism'],
  ['SUITABILITY_MATERIALS_EQUIPMENT', 'questionnaire.suitability'],
  ['USABILITY_MATERIALS_EQUIPMENT', 'questionnaire.usability'],
  ['CONDITION_MATERIALS_EQUIPMENT', 'questionnaire.conditionMaterials'],
  ['EXPOSURE_TO_EQUIPMENT_MATERIALS', 'questionnaire.exposureToEquipment'],
  ['SCHEDULE_OF_PROCESS_AND_PROCEDURES', 'questionnaire.schedule'],
  ['EXISTENCE_OF_PROCESS_AND_PROCEDURES', 'questionnaire.existence'],
  ['SUITABILITY_OF_PROCESS_AND_PROCEDURES', 'questionnaire.suitabilty'],
  ['EXECUTION_OF_PROCESS_AND_PROCEDURES', 'questionnaire.execution'],
  ['EXECUTION_OF_PROCESS_AND_PROCEDURE', 'questionnaire.execution'],
  ['EXECUTION_OF', 'questionnaire.execution'],
  // Incident Labels
  ['SLIPS_TRIPS_FALLS', 'reasons.slipsTripsFalls.stf'],
  ['DISTRACTION_LACK_OF_CONCENTRATION', 'questionnaire.distraction'],
  ['LAYOUT_SPACE_LIGHTING', 'questionnaire.layoutSpaceLighting'],
  ['VANDALISM_OR_SABOTAGE', 'causes.environment.vandalismOrSabotage'],
  ['PEOPLE', 'causes.people.title'],
  ['ENVIRONMENT', 'causes.environment.title'],
  ['EQUIPMENT_MATERIAL', 'causes.equipmentMaterial.title'],
  ['CAPABILITIES_OR_COMPETENCE', 'causes.people.capabilitiesOrCompetence'],
  ['HEALTH_OR_FATIGUE', 'causes.people.healthOrFatigue'],
]);

// Investigation Reason

export const ReasonsMap: Map<string, RadioGroupOption<ReasonsType>> = new Map([
  ['CONTACT_WITH', { value: CONTACT_WITH, label: 'reasons.contactWith.title' }],
  ['STRUCK_BY', { value: STRUCK_BY, label: 'reasons.struckBy.title' }],
  ['SLIPS_TRIPS_FALLS', { value: SLIPS_TRIPS_FALLS, label: 'reasons.slipsTripsFalls.title' }],
  ['OTHER', { value: OTHER, label: 'reasons.other.title' }],
]);

export const SubReasonsMap: Map<string, string> = new Map([
  ['UTILITIES_CHEMICALS_FIRE', 'reasons.contactWith.utilitiesChemicalsFire'],
  ['MOVING_VEHICLE', 'reasons.contactWith.movingVehicle'],
  ['MOVING_MACHINERY', 'reasons.contactWith.movingMachinery'],
  ['FIXED_OR_STATIONARY_OBJECT', 'reasons.contactWith.fixedOrStationaryObject'],
  ['MOVING_OBJECT', 'reasons.struckBy.movingObject'],
  ['FLYING_OBJECT', 'reasons.struckBy.flyingObject'],
  ['FALLING_OBJECT', 'reasons.struckBy.fallingObject'],
  ['STF', 'reasons.slipsTripsFalls.stf'],
  ['FALLS_FROM_HEIGHT', 'reasons.slipsTripsFalls.fallsFromHeight'],
  ['MANUAL_HANDLING_OR_ERGONOMICS', 'reasons.other.manualHandlingOrErgonomics'],
  ['TRAPPED_OR_CRUSHED', 'reasons.other.trappedOrCrushed'],
  ['MISS_FIRE', 'reasons.other.missFire'],
  ['OTHER', 'reasons.other.other'],
]);

// Investigation Cause

export const CausesMap: Map<string, RadioGroupOption<CausesType>> = new Map([
  ['PEOPLE', { value: PEOPLE, label: 'causes.people.title' }],
  ['ENVIRONMENT', { value: ENVIRONMENT, label: 'causes.environment.title' }],
  ['EQUIPMENT_MATERIAL', { value: EQUIPMENT_MATERIAL, label: 'causes.equipmentMaterial.title' }],
  ['WORKING_SCHEDULE', { value: WORKING_SCHEDULE, label: 'causes.workingSchedule.title' }],
]);

export const SubCausesMap: Map<string, string> = new Map([
  ['BEHAVIOUR', 'causes.people.behaviour'],
  ['CAPABILITIES_OR_COMPETENCE', 'causes.people.capabilitiesOrCompetence'],
  ['COMMUNICATION', 'causes.people.communication'],
  ['SUPERVISION', 'causes.people.supervision'],
  ['HEALTH_OR_FATIGUE', 'causes.people.healthOrFatigue'],
  ['DISTRACTION', 'causes.people.distraction'],
  ['ENVIRONMENTAL_CONDITIONS', 'causes.environment.environmentalConditions'],
  ['LAYOUT_OR_SPACE', 'causes.environment.layoutOrSpace'],
  ['WORKING_CONDITIONS', 'causes.environment.workingConditions'],
  ['HOUSEKEEPING', 'causes.environment.housekeeping'],
  ['VANDALISM_OR_SABOTAGE', 'causes.environment.vandalismOrSabotage'],
  ['SUITABILITY_OF', 'causes.equipmentMaterial.suitability'],
  ['USABILITY_OF', 'causes.equipmentMaterial.usability'],
  ['CONDITION_OF', 'causes.equipmentMaterial.condition'],
  ['EXPOSURE_TO', 'causes.equipmentMaterial.exposure'],
  ['WORKING_SCHEDULE', 'causes.workingSchedule.workingSchedule'],
  ['EXISTENCE_OF', 'causes.workingSchedule.existenceOf'],
  ['SUITABILITY_OF', 'causes.workingSchedule.suitabilityOf'],
  ['EXECUTION_OF', 'causes.workingSchedule.executionOf'],
]);

// Incident Reason

export const IncidentReasonsMap: Map<string, RadioGroupOption<IncidentReasonsType>> = new Map([
  ['CONTACT_WITH', { value: CONTACT_WITH, label: 'reasons.contactWith.title' }],
  ['STRUCK_BY', { value: STRUCK_BY, label: 'reasons.struckBy.title' }],
  ['SLIPS_TRIPS_FALLS', { value: INCIDENT_SLIPS_TRIPS_FALLS, label: 'reasons.slipsTripsFalls.stf' }],
  ['OTHER', { value: OTHER, label: 'reasons.other.title' }],
]);

export const IncidentSubReasonsMap: Map<string, string> = new Map([
  ['UTILITIES_CHEMICALS_FIRE', 'reasons.contactWith.utilitiesChemicalsFire'],
  ['MOVING_VEHICLE', 'reasons.contactWith.movingVehicle'],
  ['MOVING_MACHINERY', 'reasons.contactWith.movingMachinery'],
  ['FIXED_OR_STATIONARY_OBJECT', 'reasons.contactWith.fixedOrStationaryObject'],
  ['MOVING_OBJECT', 'reasons.struckBy.movingObject'],
  ['FLYING_OBJECT', 'reasons.struckBy.flyingObject'],
  ['FALLING_OBJECT', 'reasons.struckBy.fallingObject'],
  ['SLIP', 'questionnaire.slip'],
  ['TRIP', 'questionnaire.trip'],
  ['FALLS_FROM_HEIGHT', 'reasons.slipsTripsFalls.fallsFromHeight'],
  ['FALLS', 'questionnaire.fall'],
  ['MANUAL_HANDLING_OR_ERGONOMICS', 'reasons.other.manualHandlingOrErgonomics'],
  ['TRAPPED_OR_CRUSHED', 'reasons.other.trappedOrCrushed'],
  ['MISS_FIRE', 'reasons.other.missFire'],
  ['OTHER', 'reasons.other.other'],
]);

// Incident Cause

export const IncidentCausesMap: Map<string, RadioGroupOption<IncidentCausesType>> = new Map([
  ['PEOPLE', { value: INCIDENT_PEOPLE, label: 'causes.people.title' }],
  ['ENVIRONMENT', { value: INCIDENT_ENVIRONMENT, label: 'causes.environment.title' }],
  ['EQUIPMENT_MATERIAL', { value: INCIDENT_EQUIPMENT_MATERIAL, label: 'causes.equipmentMaterial.title' }],
  ['PROCESS_AND_PROCEDURE', { value: PROCESS_AND_PROCEDURE, label: 'questionnaire.procProd' }],
]);

export const IncidentSubCausesMap: Map<string, string> = new Map([
  ['BEHAVIOUR', 'causes.people.behaviour'],
  ['CAPABILITIES_OR_COMPETENCE', 'causes.people.capabilitiesOrCompetence'],
  ['COMMUNICATION', 'causes.people.communication'],
  ['SUPERVISION', 'causes.people.supervision'],
  ['HEALTH_OR_FATIGUE', 'causes.people.healthOrFatigue'],
  ['DISTRACTION_LACK_OF_CONCENTRATION', 'questionnaire.distraction'],
  ['SITE_ENVIRONMENTAL_CONDITIONS', 'questionnaire.siteConditions'],
  ['LAYOUT_SPACE', 'questionnaire.layoutSpaceLighting'],
  ['WORKING_ENVIRONMENT', 'questionnaire.workingEnv'],
  ['HOUSEKEEPING', 'causes.environment.housekeeping'],
  ['VANDALISM_OR_SABOTAGE', 'causes.environment.vandalismOrSabotage'],
  ['SUITABILITY_MATERIALS_EQUIPMENT', 'questionnaire.suitability'],
  ['USABILITY_MATERIALS_EQUIPMENT', 'questionnaire.usability'],
  ['CONDITION_MATERIALS_EQUIPMENT', 'questionnaire.conditionMaterials'],
  ['EXPOSURE_TO_EQUIPMENT_MATERIALS', 'questionnaire.exposureToEquipment'],
  ['SCHEDULE_OF_PROCESS_AND_PROCEDURES', 'questionnaire.schedule'],
  ['EXISTENCE_OF_PROCESS_AND_PROCEDURES', 'questionnaire.existence'],
  ['SUITABILITY_OF_PROCESS_AND_PROCEDURES', 'questionnaire.suitabilty'],
  ['EXECUTION_OF_PROCESS_AND_PROCEDURES', 'questionnaire.execution'],
]);

// Hire Risk Type

export const HireCategoriesLabels: Map<string, RadioGroupOption<HireTypes>> = new Map([
  ['HIRE_PEOPLE', { value: HIRE_PEOPLE, label: 'hire.people.title' }],
  ['HIRE_ENVIRONMENT', { value: HIRE_ENVIRONMENT, label: 'hire.environment.title' }],
  ['HIRE_MATERIALS', { value: HIRE_MATERIALS, label: 'hire.materials.title' }],
  ['HIRE_EQUIPMENT', { value: HIRE_EQUIPMENT, label: 'hire.equipment.title' }],
]);

export const HireSubTypesMap: Map<string, string> = new Map([
  ['ACCESS_AND_EGRESS', 'hire.people.accessAndEgress'],
  ['BURNS', 'hire.people.burns'],
  ['ELECTROCUTION', 'hire.people.electrocution'],
  ['FALLS_FROM_HEIGHT', 'hire.people.fallsFromHeight'],
  ['SUFFOCATION_OR_ASPHYXIATION', 'hire.people.suffocationOrAsphyxiation'],
  ['MANUAL_HANDLING', 'hire.people.manualHandling'],
  ['SLIP_TRIP_FALL', 'hire.people.slipTripFall'],
  ['LONE_WORKING', 'hire.people.loneWorking'],
  ['TRESPASS', 'hire.people.trespass'],
  ['CLOSE_WORKING_TO_OTHERS', 'hire.people.closeWorkingToOthers'],
  ['INADEQUATE_LIGHTING', 'hire.environment.inadequateLighting'],
  ['NOISE', 'hire.environment.noise'],
  ['CONFINED_SPACE', 'hire.environment.confinedSpace'],
  ['DEEP_WATER', 'hire.environment.deepWater'],
  ['EXPLOSION', 'hire.environment.explosion'],
  ['GROUND_COLLAPSE_OR_FAILURE', 'hire.environment.groundCollapseOrFailure'],
  ['STRUCTURAL_FAILURE', 'hire.environment.structuralFailure'],
  ['NEARBY_OPERATIONS_OR_COMMUNITIES', 'hire.environment.nearbyOperationsOrCommunities'],
  ['WEATHER_CONDITIONS', 'hire.environment.weatherConditions'],
  ['DUST', 'hire.materials.dust'],
  ['FIRE_HOT_WORK_FLAMMABLE', 'hire.materials.fireHotWorkFlammable'],
  ['VIBRATION', 'hire.materials.vibration'],
  ['UNDERGROUND_OVERGROUND_SERVICES', 'hire.materials.undergroundOvergroundServices'],
  ['HAZARDOUS_SUBSTANCES', 'hire.materials.hazardousSubstances'],
  ['STORED_ENERGY', 'hire.materials.storedEnergy'],
  ['FAILURE_OF_TOOLS_EQUIPMENT', 'hire.equipment.failureOfToolsOrEquipment'],
  ['FALLING_OBJECTS', 'hire.equipment.fallingObject'],
  ['FLYING_OJBECTS', 'hire.equipment.flyingObjects'],
  ['HIT_BY_VEHICLE', 'hire.equipment.hitByVehicle'],
  ['FAILURE_OF_LIFTING_EQUIPMENT', 'hire.equipment.failureOfLiftingEquipment'],
  ['ENTRAPMENT', 'hire.equipment.entrapment'],
  ['ACCIDENTAL_START_UP', 'hire.equipment.accidentalStartup'],
  ['STORED_ENERGY', 'hire.equipment.storedEnergy'],
  ['CLOSE_WORKING_MACHINES', 'hire.equipment.closeWorkingMachines'],
]);

// Enum labels

export const ActionImportanceLabel: Record<ActionImportance | string, string> = {
  '0': 'shared.lowPriority',
  LOW: 'shared.lowPriority',
  '1': 'shared.mediumPriority',
  MEDIUM: 'shared.mediumPriority',
  '2': 'shared.highPriority',
  HIGH: 'shared.highPriority',
};

export const ActionStatusLabel: Record<ActionStatus | string, string> = {
  '0': 'action.open',
  OPEN: 'action.open',
  '1': 'action.close',
  CLOSED: 'action.close',
  '2': 'action.overdue',
  OVERDUE: 'action.overdue',
};

export const ActionSourceLabel: Record<ActionSourceType | string, string> = {
  '0': 'action.event',
  EVENT: 'action.event',
  '1': 'shared.riskAssessment',
  RISK_ASSESSMENT: 'shared.riskAssessment',
};

export const InvestigationStatusLabel: Record<string | number, string> = {
  OPEN: 'incidents.open',
  0: 'incidents.open',
  CLOSED: 'incidents.closed',
  1: 'incidents.closed',
};

// Tables

export const SORT_DIRECTIONS: { [k: string]: SortOrder[] } = {
  ASCEND: ['ascend'],
  DESCEND: ['descend'],
  BOTH: ['ascend', 'descend'],
};

// Historical data

export const HistoricalDataColumns: Record<string, string> = {
  EVENT_TYPE: 'incidents.incidentType',
  REPORT_DATE: 'incidents.reportDate',
  COMPANY: 'shared.company',
  DIVISION: 'shared.division',
  REPORTED_BY: 'incidents.reportedBy',
};

export enum TimeIntervals {
  TODAY = 'TODAY',
  LAST_7_DAYS = 'LAST_7_DAYS',
  THIS_MONTH = 'THIS_MONTH',
  LAST_6_MONTH = 'LAST_6_MONTH',
  THIS_YEAR = 'THIS_YEAR',
  ALL_TIME = 'ALL_TIME',
  CUSTOM = 'CUSTOM',
}

export const TimeIntervalsLabel: Record<number | string, string> = {
  0: 'charts.timeIntervals.today',
  TODAY: 'charts.timeIntervals.today',
  1: 'charts.timeIntervals.last7Days',
  LAST_7_DAYS: 'charts.timeIntervals.last7Days',
  2: 'charts.timeIntervals.thisMonth',
  THIS_MONTH: 'charts.timeIntervals.thisMonth',
  3: 'charts.timeIntervals.last6Month',
  LAST_6_MONTH: 'charts.timeIntervals.last6Month',
  4: 'charts.timeIntervals.thisYear',
  THIS_YEAR: 'charts.timeIntervals.thisYear',
  5: 'charts.timeIntervals.allTime',
  ALL_TIME: 'charts.timeIntervals.allTime',
  6: 'charts.timeIntervals.custom',
  CUSTOM: 'charts.timeIntervals.custom',
};

export const RISK_SCORE_VALUES = [1, 2, 3, 4, 5, 6, 8, 9, 10, 12, 15, 16, 20, 25];

export const RISK_SCORE_MATRIX: number[][] = [
  [1, 2, 3, 4, 5],
  [2, 4, 6, 8, 10],
  [3, 6, 9, 12, 15],
  [4, 8, 12, 16, 20],
  [5, 10, 15, 20, 25],
];

export const oneDayInMs = 86400000;
